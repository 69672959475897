import React, { Section } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FirstSlides from '../ImagenVisual/FirstSlides';
import BrainY from '../../assets/BrainY Color.svg';
import Helmet from 'react-helmet';
import { ReactSEOMetaTags } from 'react-seo-meta-tags';

const useStyles = makeStyles({
  root: {
    height: '100hv'
  }
});
const metaDatos = {
  url: 'https://google.com/about',
  title: 'Conoce mas sobre nuestras promociones',
  datePublished: '2019-10-06T13:56:03.123Z',
  description:
    "This is a 200 character long description of this web page which is quite interesting and which describes its contents well with a lot of relevant keywords and isn't just general marketing mumbo-jumbo.",
  language: 'es-MX',
  image: 'http://website.com/image.png',
  author: {
    email: 'alek@alekzen.com',
    name: 'Alek Zen',
    image: 'http://alekzen.com/logo.jpg'
  },
  site: {
    siteName: 'Geek Studio',
    searchUrl: 'https://www.google.com/search?q=GeekStudioDev'
  }
};
const MKTNav = () => {
  const classes = useStyles();
  return (
    <>
      <ReactSEOMetaTags
        website={{ ...metaDatos }}
        breadcrumb={[
          { name: 'Geek Studio MKT ', url: 'https://geekstudio.dev/mkt/nav' },
          { name: 'Blog', url: 'https://geekstudio.dev/blog' }
        ]}
        organization={{
          name: 'Geek Studio Developers',
          legalName: 'Geek Studio SAS',
          url: 'https://geekstudio.dev',
          logo: 'https://google.com/logo.jpg'
        }}
        facebook={{ facebookAppId: 'abc123' }}
        twitter={{ twitterUser: '@mickey_mouse' }}
      />
      <Helmet>
        <title>Geek Studio MKT Platform</title>
        <meta name="description" content="Plataforma de marketing Avanzada" />
        <meta itemprop="name" content="Geek Studio" />
        <meta itemprop="description" content="Plataforma de marketing Avanzada" />
        <meta itemprop="image" content="https://geekstudio.dev/static/media/Logo.75f27e2d.svg" />
        <meta property="og:url" content="https://geekstudio.dev/mkt/nav" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Geek Studio" />
        <meta property="og:description" content="Plataforma de marketing Avanzada" />
        <meta property="og:image" content="https://geekstudio.dev/static/media/Logo.75f27e2d.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Geek Studio" />
        <meta name="twitter:description" content="Plataforma de marketing Avanzada" />
        <meta name="twitter:image" content="https://geekstudio.dev/static/media/Logo.75f27e2d.svg" />
      </Helmet>
      Conoce mas sobre nuestras promociones
      <FirstSlides src="https://slides.com/alekzen/geekstudiodevelopers/fullscreen" />
    </>
  );
};

export default MKTNav;
