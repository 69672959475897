import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ThreeAlek from './ThreeAlek'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ThreeDWorld from './ThreeDWorld'
import ThreeNebula from './ThreeNebula'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }
}));

const ThreeJSRepositorio = () => {
    const classes = useStyles();

    return (

        <div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            Animacion automatizada <ThreeDWorld />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper}>Lineas simples</Paper>
                        <ThreeAlek />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>Nebula(experimental)</Paper>
                        <ThreeNebula />
                    </Grid>


                </Grid>
            </div>
        </div>


    )
}

export default ThreeJSRepositorio
