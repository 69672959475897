import React, { useState } from 'react';
import { Typography, Button, Box, Grid } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
//media
import CTAiframe from './CTAiframe';
import CTAimg from './CTAimg';
import CTAvideo from './CTAvideo';
import CTADialog from './CTADialog';

const Styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: '0',
    backgroundImage:
      theme.palette.mode === 'light'
        ? `linear-gradient(180deg, ${alpha(theme.palette.grey[300], 0)} 0%, ${theme.palette.grey[300]} 100%)`
        : 'none',
    height: 'auto'
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    paddingTop: '20%',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '1%'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '5%',
      paddingLeft: '1%',
      paddingRight: '3%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '5%',
      paddingLeft: '3%',
      paddingRight: '3%'
    }
  },
  title: {
    margin: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      fontSize: '30px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px'
    }
  },
  desc: {
    marginTop: theme.spacing(3),
    lineHeight: 'normal',
    '& *': {
      lineHeight: 'normal'
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '19px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '22px'
    }
  },
  buttonContainer: {
    padding: theme.spacing(4)
  }
}));

CallToAction.propTypes = {
  mediaURL: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  BLink: PropTypes.string.isRequired,
  position: PropTypes.string,
  formulario: PropTypes.bool.isRequired,
  redirectURL: PropTypes.string.isRequired,
  typeF: PropTypes.string.isRequired,
  formURL: PropTypes.string.isRequired
};

function CallToAction({
  mediaURL,
  mediaType,
  title,
  description,
  textButton,
  BLink,
  formulario = false,
  redirectURL,
  typeF,
  formURL
}) {
  //estilos
  const classes = Styles();
  //estados
  const [open, setOpen] = useState(false);
  //subcomponente
  const Media = () => {
    switch (mediaType) {
      case 'iframe':
        return <CTAiframe url={mediaURL} />;
      case 'img':
        return <CTAimg url={mediaURL} />;
      case 'video':
        return <CTAvideo url={mediaURL} autoPlay={true} loop={true} />;
      default:
        return <CTAiframe url={mediaURL} />;
    }
  };
  return (
    <>
      <Box component="div" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={classes.mediaContainer} id="media-cta">
              {Media()}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="div" className={classes.container}>
              <Typography variant="h1" className={classes.title} id="title-cta">
                {title}
              </Typography>
              <Typography variant="body1" className={classes.desc} id="description-cta">
                {parse(description)}
              </Typography>
              <Box component="div" className={classes.buttonContainer}>
                <Button
                  id="button-cta"
                  variant="contained"
                  href={formulario ? undefined : BLink}
                  target={formulario ? undefined : '_blank'}
                  onClick={() => {
                    if (formulario) {
                      setOpen(true);
                    }
                  }}
                >
                  {textButton}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CTADialog open={open} setOpen={setOpen} typeF={typeF} formURL={formURL} />
    </>
  );
}

export default CallToAction;
