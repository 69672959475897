import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


const useStyles = makeStyles((theme) => ({
    info: {
        position: 'absolute',
        top: '10px',
        width: '100%',
        textAlign: 'center',
        zIndex: 100,
        display: 'block',
        color: 'white'

    }

}));




let scene, camera, renderer, material, points, geometry, line, text, txtGeometry, loader, cube


const ThreeNebula = () => {
    const classes = useStyles();

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);

    const fov = 75;
    const aspect = 2;  // the canvas default
    const near = 0.1;
    const far = 5;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.z = 2;

    const scene = new THREE.Scene();

    {
        const color = 0xFFFFFF;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(-1, 2, 4);
        scene.add(light);
    }

    const boxWidth = 1;
    const boxHeight = 1;
    const boxDepth = 1;
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

    function makeInstance(geometry, color, x) {
        const material = new THREE.MeshPhongMaterial({ color });

        const cube = new THREE.Mesh(geometry, material);
        scene.add(cube);

        cube.position.x = x;

        return cube;
    }

    const cubes = [
        makeInstance(geometry, 0x44aa88, 0),
        makeInstance(geometry, 0x8844aa, -2),
        makeInstance(geometry, 0xaa8844, 2),
    ];


    useEffect(() => {
        document.getElementById("Lines").appendChild(renderer.domElement)
    })

    function render(time) {
        time *= 0.001;  // convert time to seconds

        cubes.forEach((cube, ndx) => {
            const speed = 1 + ndx * .1;
            const rot = time * speed;
            cube.rotation.x = rot;
            cube.rotation.y = rot;
        });

        renderer.render(scene, camera);

        requestAnimationFrame(render);
    }
    requestAnimationFrame(render);



    return (
        <>
            <div id="Lines"></div>
            <canvas className={classes.info} id="info">Somos Geek Studio</canvas>
        </>
    )
}







export default ThreeNebula


