import React from 'react';
import PropTypes from 'prop-types';

FirstSlides.propTypes = {
  src: PropTypes.string.isRequired,
  with: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};

function FirstSlides({ src, width = '100%', height = '500px', className }) {
  return (
    <div className={className}>
      <iframe src={src} style={{ border: '0', width: width, height: height }} title="iframe" />
    </div>
  );
}

export default FirstSlides;
