import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


const useStyles = makeStyles((theme) => ({
    info: {
        position: 'absolute',
        top: '10px',
        width: '100%',
        textAlign: 'center',
        zIndex: 100,
        display: 'block',
        color: 'white'

    }

}));




let scene, camera, renderer, material, points, geometry, line


const ThreeAlek = () => {
    const classes = useStyles();

    scene = new THREE.Scene();
    renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 500);
    camera.position.set(0, 0, 100);
    camera.lookAt(0, 0, 100);

    material = new THREE.LineDashedMaterial({
        color: 0xffffff,
        linewidth: 1,
        scale: 1,
        dashSize: 3,
        gapSize: 1,
    });

    points = [];
    points.push(new THREE.Vector3(- 10, 0, 0));
    points.push(new THREE.Vector3(0, 10, 0));
    points.push(new THREE.Vector3(10, 0, 0));

    geometry = new THREE.BufferGeometry().setFromPoints(points);

    line = new THREE.Line(geometry, material);

    scene.add(line);
    

    useEffect(() => {
        document.getElementById("Lines").appendChild(renderer.domElement)
    })

    animate()




    return (
        <>
            <div id="Lines"></div>
            <div className={classes.info} id="info">Somos Geek Studio</div>
        </>
    )
}

const animate = () => {
    requestAnimationFrame(animate)

    line.rotation.x += 0.01
    line.rotation.y += 0.01

    renderer.render(scene, camera)
}



export default ThreeAlek


