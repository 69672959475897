import React, { useState } from 'react';
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';
import { MButton } from 'src/components/@material-extend';
import { firestore } from 'src/contexts/FirebaseContext';
import DialogAnalisis from '../DialogAnalisis';
import toDate from 'date-fns/esm/fp/toDate/index.js';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

FormAltaCredito.propTypes = {
  textbutton: PropTypes.string,
  fromstart: PropTypes.bool
};

function FormAltaCredito({ textbutton, fromstart = false }) {
  //datos de entrada
  const [open, setOpen] = useState(false);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [monto, setMonto] = useState('');
  const [plazo, setPlazo] = useState('1');
  //errores de mensajes
  const [errorNombre, setErrorNombre] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorEmpresa, setErrorEmpresa] = useState('');
  const [errorMonto, setErrorMonto] = useState('');
  //adicionales
  const [mountD, setMountD] = useState(false);
  const [SDialogOpen, setSDialogOpen] = useState(true);
  //el resto del codigo

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validarDatos = () => {
    var validNombre = nombre.length > 0;
    var validEmail = email.indexOf('@') !== -1 && email.indexOf('.com') !== -1;
    var validPhone = phone.length >= 9;
    var validEmpresa = empresa !== '';
    var validMonto;
    try {
      parseFloat(monto);
      validMonto = monto > 0;
    } catch (err) {
      validMonto = false;
    }
    var validPlazo;
    try {
      parseInt(plazo);
      validPlazo = plazo > 0;
    } catch (err) {
      validPlazo = false;
    }
    if (!validNombre) {
      setErrorNombre('este campo es requerido');
    }
    if (!validEmail) {
      setErrorEmail('ingresa un correo valido');
    }
    if (!validPhone) {
      setErrorPhone('ingresa un numero de telefono valido');
    }
    if (!validEmpresa) {
      setErrorEmpresa('este campo es requerido');
    }
    if (!validMonto) {
      setErrorMonto('ingresa un valor valido');
    }
    return validNombre && validEmail && validPhone && validEmpresa && validMonto && validPlazo;
  };

  const handleAlta = () => {
    //setOpen(false);
    if (validarDatos()) {
      firestore
        .collection('creditos')
        .doc()
        .set({
          Nombre: nombre,
          Telefono: phone,
          Email: email,
          Emnpresa: empresa,
          Monto: monto,
          Plazo: plazo,
          Enganche: 0,
          Tasa: 0.24,
          Aprobado: false,
          Rechazado: false,
          ML: false,
          FechaAlta: toDate(Date.now())
        })
        .then(() => {
          if (!fromstart) {
            setEmail('');
            setEmpresa('');
            setPhone('');
            setNombre('');
            setPlazo('');
            setMonto('');
            setOpen(false);
          } else {
            setMountD(true);
            setSDialogOpen(true);
            setOpen(false);
          }
        });
    } else {
      console.log('error');
    }
  };

  return (
    <div>
      <MButton variant="contained" color="primary" onClick={handleClickOpen}>
        {textbutton}
      </MButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Captura los datos para dar de alta un nuevo credito</DialogTitle>
        <DialogContent>
          <DialogContentText>Al capturar los datos se procesarán los datos del credito.</DialogContentText>
          <TextField
            required
            autoFocus
            fullWidth
            error={errorNombre !== '' ? true : false}
            helperText={errorNombre}
            onChange={(e) => {
              setNombre(e.target.value);
              setErrorNombre('');
            }}
            value={nombre}
            name="nombre"
            type="text"
            margin="dense"
            variant="outlined"
            label="Nombre"
          />
          <TextField
            required
            autoFocus
            fullWidth
            error={errorEmail !== '' ? true : false}
            helperText={errorEmail}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorEmail('');
            }}
            value={email}
            name="email"
            type="email"
            margin="dense"
            variant="outlined"
            label="Correo"
          />
          <TextField
            required
            autoFocus
            fullWidth
            error={errorPhone !== '' ? true : false}
            helperText={errorPhone}
            onChange={(e) => {
              setPhone(e.target.value);
              setErrorPhone('');
            }}
            value={phone}
            name="phone"
            type="phone"
            margin="dense"
            variant="outlined"
            label="Telefono"
          />
          <TextField
            required
            autoFocus
            fullWidth
            error={errorEmpresa !== '' ? true : false}
            helperText={errorEmpresa}
            onChange={(e) => {
              setEmpresa(e.target.value);
              setErrorEmpresa('');
            }}
            value={empresa}
            name="empresa"
            type="text"
            margin="dense"
            variant="outlined"
            label="Empresa"
          />
          <TextField
            required
            autoFocus
            fullWidth
            error={errorMonto !== '' ? true : false}
            helperText={errorMonto}
            onChange={(e) => {
              setMonto(e.target.value);
              setErrorMonto('');
            }}
            value={monto}
            name="monto"
            type="number"
            margin="dense"
            variant="outlined"
            label="Monto"
          />

          <FormLabel>Plazo</FormLabel>
          <RadioGroup
            required
            autoFocus
            fullWidth
            onChange={(e) => {
              setPlazo(e.target.value);
            }}
            value={plazo}
            name="plazo"
            type="number"
            margin="dense"
            variant="outlined"
            label="Plazo (years)"
          >
            <FormControlLabel value="1" control={<Radio />} label="1 año" />
            <FormControlLabel value="3" control={<Radio />} label="3 año" />
            <FormControlLabel value="5" control={<Radio />} label="5 año" />
            <FormControlLabel value="7" control={<Radio />} label="7 año" />
            <FormControlLabel value="10" control={<Radio />} label="10 año" />
            <FormControlLabel value="15" control={<Radio />} label="15 año" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button onClick={handleAlta} variant="contained">
            Alta
          </Button>
        </DialogActions>
      </Dialog>
      {mountD ? <DialogAnalisis monto={monto} plazo={plazo} SDialog={SDialogOpen} setSDialog={setSDialogOpen} /> : null}
    </div>
  );
}

export default FormAltaCredito;
