import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Link } from '@material-ui/core';
import { CTAiframe, CTAimg, CTAvideo } from 'src/components/_external-pages/calltoaction';

function CardCTA({ title, mediaType, mediaURL }) {
  //subcomponentes
  const Media = () => {
    switch (mediaType) {
      case 'iframe':
        return <CTAiframe url={mediaURL} width="100%" height="200px" cta={false} />;
      case 'img':
        return <CTAimg url={mediaURL} width="100%" />;
      case 'video':
        return <CTAvideo url={mediaURL} autoPlay={true} width="100%" />;
      default:
        return <CTAiframe url={mediaURL} width="100px" height="100px" cta={false} />;
    }
  };
  return (
    <Card sx={{ width: '100%', position: 'relative' }}>
      <CardContent>
        <Link to={'/mkt?cta=' + title.replaceAll(' ', '_')} component={RouterLink}>
          <Grid container>
            <Grid item xs={12}>
              {Media()}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{title}</Typography>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
}

export default CardCTA;
