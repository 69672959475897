import RowTA from './contenidos/RowTA';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  root: {}
});

export default function TablaAmortizacion({ Cred }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableContainer sx={{ minWidth: 800, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mes</TableCell>
              <TableCell align="center">Saldo</TableCell>
              <TableCell align="center">Abono A Capital</TableCell>
              <TableCell align="center">Interes Pagado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Cred.map((row) => (
              <RowTA key={'mensualidad-' + row.mes} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
