import { merge } from 'lodash';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { ApexChartsOption } from 'src/components/Charts/Apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {}
}));

// ----------------------------------------------------------------------

GraficaAmort.propTypes = {
  className: PropTypes.string,
  Cred: PropTypes.array.isRequired
};

function GraficaAmort({ className, Cred, ...other }) {
  const classes = useStyles();
  let cap = [];
  let inte = [];
  let sald = [];
  let mes = [];

  Cred.map((cred) => {
    cap.push(cred.abonocapital);
    inte.push(cred.interespagado);
    sald.push(cred.saldo);
    mes.push(cred.mes);
    return 0;
  });

  const chartData = [
    {
      name: 'Saldo',
      type: 'column',
      data: sald
    },
    {
      name: 'Abono a Capital',
      type: 'area',
      data: cap
    },
    {
      name: 'Interes Pagado',
      type: 'line',
      data: inte
    }
  ];
  const chartOptions = merge(ApexChartsOption(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '12%', endingShape: 'rounded' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: mes,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(2) + '$';
          }
          return y;
        }
      }
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardHeader title="Grafica de amortizacion" subheader="del credito seleccionado" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}

export default GraficaAmort;
