import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import useLocalStorage from 'src/hooks/useLocalStorage';

const WorkSpacesC = createContext();

function WorkSpacesProvider({ children }) {
  //estados
  const { user } = useAuth();
  const [ws, setWS] = useState([]);
  const [wsS, setwsS] = useLocalStorage('works', '');
  //metodos
  async function getWs() {
    let dat = [];
    await firestore
      .collection('integrantesws')
      .where('email', '==', user.email || '')
      .get()
      .then((querySin) => {
        querySin.forEach((doc) => {
          const { wsid } = doc.data();
          firestore
            .collection('workspaces')
            .doc(wsid)
            .get()
            .then((dociw) => {
              dat.push({ id: dociw.id, ...dociw.data() });
            });
        });
      });
    await firestore
      .collection('workspaces')
      .where('creadorId', '==', user.id || '')
      .onSnapshot((queryS) => {
        queryS.forEach((doc) => {
          dat.push({ id: doc.id, ...doc.data() });
        });

        setWS(dat);
      });
  }
  useEffect(() => {
    getWs();
    // eslint-disable-next-line
  }, [user]);
  return <WorkSpacesC.Provider value={{ ws: ws, wsS: wsS, setwsS: setwsS }}>{children}</WorkSpacesC.Provider>;
}

export { WorkSpacesProvider, WorkSpacesC };
