import React from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { InlineWidget } from 'react-calendly';
import CTAiframe from './CTAiframe';
import PropTypes from 'prop-types';
import CustomizedSteppers from 'src/components/ImagenVisual/CustomizedStepper';

CTADialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  redirectURL: PropTypes.string.isRequired,
  typeF: PropTypes.string.isRequired,
  formURL: PropTypes.string.isRequired
};

function CTADialog({ open, setOpen, redirectURL, typeF, formURL }) {
  //subcomponentes
  const form = () => {
    if (typeF === 'iframe') {
      return <CTAiframe url={formURL} width="500px" height="400px" />;
    }
    if (typeF === 'calendly') {
      return <InlineWidget url={formURL} styles={{ width: '500px', height: '400px' }} />;
    }
    return <CustomizedSteppers />;
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>{form()}</DialogContent>
      </Dialog>
    </>
  );
}

export default CTADialog;
