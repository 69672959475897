import { useRef, useState } from 'react';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
// hooks
import useWorkspaces from 'src/hooks/useWorkspaces';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { ws, setwsS, wsS } = useWorkspaces();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangews = (value) => {
    setwsS(value);
    handleClose();
  };
  const currentWS = ws.find((item) => item.id === wsS);
  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        {currentWS ? currentWS.name : 'none'}
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
        {ws.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === wsS}
            onClick={() => handleChangews(option.id)}
            sx={{ py: 1, px: 2.5 }}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
