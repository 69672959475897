import React from 'react';
import PropTypes from 'prop-types';

CTAvideo.propTypes = {
  url: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
};

function CTAvideo({ url, autoPlay, loop, width, height }) {
  return <video src={url} width={width || '88%'} height={height || 'auto'} autoPlay={autoPlay} loop={loop} controls />;
}

export default CTAvideo;
