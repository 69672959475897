import React, { Component } from 'react';
import { Dialog, IconButton, Grid, AppBar, Toolbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import TablaAmortizacion from './TablaAmortizacion';
import GraficaAmort from './GraficaAmort';
import GraficaAcumulado from './GraficaAcumulado';
import GraficaDesp from './GraficaDesp';
import GraficaVal from './GraficaVal';
import PropTypes from 'prop-types';
const axios = require('axios').default;

class DialogAnalisis extends Component {
  state = {
    Dopen: true,
    creditS: [],
    acumulados: {
      interesacumulado: [],
      valorcasaacumulado: []
    },
    depreciacioncasa: {
      pagocasad: [],
      valorcasad: []
    },
    valorcasa: {
      pagadocasa: [],
      valorcasa: []
    },
    show: false,
    loading: false,
    error: false,
    errorText: ''
  };

  closeDialog() {
    this.props.setSDialog(false);
  }

  async componentDidMount() {
    this.setState({ creditsS: [] });
    this.setState({
      show: false,
      loading: true,
      error: false
    });
    await axios
      .get('https://us-central1-botsi-oxrr.cloudfunctions.net/Creditos', {
        params: {
          hv: this.props.monto,
          dpp: 0.24,
          mort: 0,
          years: this.props.plazo
        }
      })
      .then((resp) => {
        try {
          this.setState({
            creditS: resp.data[0].periodos,
            acumulados: resp.data[0].acumulados,
            depreciacioncasa: resp.data[0].depreciacioncasa,
            valorcasa: resp.data[0].valorcasa,
            loading: false,
            show: true
          });
          console.log(this.state);
        } catch (err) {
          console.log('error intentando obtener la informacion' + err);
          this.setState({
            errorText: err,
            error: true,
            loading: false
          });
        }
      });
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.SDialog}
        onClose={() => {
          this.closeDialog();
        }}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.closeDialog();
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {this.state.loading ? <h1>Cargando...</h1> : null}
            {this.state.error ? <h1>La solicitud a fallado {this.state.errorText}</h1> : null}
            {this.state.creditS.length !== 0 && this.state.show ? <GraficaAmort Cred={this.state.creditS} /> : null}
          </Grid>
          <Grid item xs={12} md={6} lg={10}>
            {this.state.creditS.length !== 0 && this.state.show ? (
              <TablaAmortizacion Cred={this.state.creditS} />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={10}>
            {this.state.creditS.length !== 0 && this.state.show ? (
              <GraficaAcumulado Acumulado={this.state.acumulados} />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={10}>
            {this.state.depreciacioncasa !== undefined && this.state.show ? (
              <GraficaDesp DespCasa={this.state.depreciacioncasa} />
            ) : null}
          </Grid>
          <Grid item xs={12} md={6} lg={10}>
            {this.state.valorcasa !== undefined && this.state.show ? (
              <GraficaVal casaVal={this.state.valorcasa} />
            ) : null}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

DialogAnalisis.propTypes = {
  monto: PropTypes.number.isRequired,
  plazo: PropTypes.number.isRequired,
  SDialog: PropTypes.bool.isRequired,
  setSDialog: PropTypes.func.isRequired
};

export default DialogAnalisis;
