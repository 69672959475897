import PropTypes from 'prop-types';
//import { Icon } from '@iconify/react';
import React from 'react';
//import arrowIosUpwardFill from '@iconify-icons/eva/arrow-ios-upward-fill';
//import arrowIosDownwardFill from '@iconify-icons/eva/arrow-ios-downward-fill';
import { makeStyles } from '@material-ui/core/styles';
import {
  /*Box,
  Table,
  Collapse,*/
  TableRow,
  /*TableHead,
  TableBody,*/
  TableCell
  /*Typography,
  IconButton*/
} from '@material-ui/core';

// ----------------------------------------------------------------------

const useRowStyles = makeStyles({
  root: {}
});

// ----------------------------------------------------------------------

Row.propTypes = {
  row: PropTypes.shape({
    mes: PropTypes.string.isRequired,
    saldo: PropTypes.number.isRequired,
    abonocapital: PropTypes.number.isRequired,
    interespagado: PropTypes.number.isRequired
  }).isRequired
};

export default function Row({ row }) {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>{row.mes}</TableCell>
        <TableCell align="center">{row.saldo.toFixed(2) + ' $'}</TableCell>
        <TableCell align="center">{row.abonocapital.toFixed(2) + ' $'}</TableCell>
        <TableCell align="center">{row.interespagado.toFixed(2) + ' $'}</TableCell>
      </TableRow>
    </>
  );
}
