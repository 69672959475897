import { merge } from 'lodash';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import ApexChartsOption from 'src/components/Charts/Apexcharts/ApexChartsOption';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {}
}));

// ----------------------------------------------------------------------

GraficaAcumulado.propTypes = {
  className: PropTypes.string,
  Acumulado: PropTypes.shape({
    interesacumulado: PropTypes.array.isRequired,
    valorcasaacumulado: PropTypes.array.isRequired
  }).isRequired
};

function GraficaAcumulado({ className, Acumulado, ...other }) {
  const classes = useStyles();
  var mesn = 1;
  const InteresA = Acumulado.interesacumulado;
  const valorCasa = Acumulado.valorcasaacumulado;
  let mes = [];

  InteresA.map(() => {
    mes.push(mesn);
    mesn++;
    return 0;
  });

  const chartData = [
    {
      name: 'Interes Acumulado',
      type: 'area',
      data: InteresA
    },
    {
      name: 'Valor del Activo Acumulado',
      type: 'area',
      data: valorCasa
    }
  ];
  const chartOptions = merge(ApexChartsOption(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '12%', endingShape: 'rounded' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: mes,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(2) + '$';
          }
          return y;
        }
      }
    }
  });

  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardHeader title="Interes Acumulado vs Valor del Activo Acumulado" subheader="del credito seleccionado" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}

export default GraficaAcumulado;
