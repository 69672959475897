import React from 'react';
import ReactIframe from 'react-iframe';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  root: {
    border: '0',
    [theme.breakpoints.up('xs')]: {
      height: '500px'
    },
    [theme.breakpoints.up('md')]: {
      height: '550px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '600px'
    }
  },
  none: {
    border: '0'
  }
}));

CTAiframe.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  cta: PropTypes.bool
};

function CTAiframe({ url, width, height, cta = true }) {
  const classes = Styles();
  return (
    <ReactIframe src={url} width={width || '100%'} height={height} className={cta ? classes.root : classes.none} />
  );
}

export default CTAiframe;
