import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

CTAimg.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string
};

function CTAimg({ url, height, width }) {
  return <Box component="img" src={url} sx={{ width: width || '88%', height: height || 'auto' }} />;
}

export default CTAimg;
