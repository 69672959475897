import React, { Section } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FirstSlides from '../ImagenVisual/FirstSlides';
import BrainY from '../../assets/BrainY Color.svg'



const useStyles = makeStyles({
    root: {
        height: '100hv',
    },
    heroIimage: {
        backgroundImage: 'linear- gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/alek.jpg")',

        /* Set a specific height */
        height: '50 %',

        /* Position and center the image to scale nicely on all screens */
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'absolute',

    },
    heroText: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        color: 'black',
        zIndex: 3,



    },
    boton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
});

const LPCaptura2 = () => {
    const classes = useStyles();
    return (
        <>

            {/* <div className={classes.root}>
                <div className={classes.heroImage}>
                    <div className={classes.heroText}>
                        <h1>Somos Geek Studio</h1>
                        <img src={BrainY} />
                        <p>Desarrollamos soluciones de negocio</p>
                        <button>Contratar</button>
                    </div>
                </div>
            
            </div > */}
            

            <FirstSlides src="https://slides.com/alekzen/geekstudiodevelopers/fullscreen" />

        </>
    )
}

export default LPCaptura2
