import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CompuVis from 'src/components/tf/ComputerVision/CompuVis';
import FaceDetect from 'src/components/tf/FaceDetect';
import ObjectDetection from 'src/components/tf/ObjectDetection/ObjectDetection';
import Poses from 'src/components/tf/Poses/Poses';
import Tf from 'src/components/tf/Tf';
import FaceApi from '../FaceApi/FaceApi';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

export default function VisionAtificialDemos() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>Deteccion de rostos</Paper>
            <FaceDetect />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>Deteccion de objetos</Paper>
            <ObjectDetection />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              Modelos CV <CompuVis />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              Detectar Poses <Poses />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              Algoritmos <Tf />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
